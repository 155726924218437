export const environment = {
  "production": true,
  "endpoint": "https://api.ispeak.fablevision-dev.com",
  "cognito": {
    "endpoint": "https://production-ispeak-us-east-1.auth.us-east-1.amazoncognito.com",
    "poolId": "us-east-1_WHfD4GeK3",
    "accountId": "533267126156",
    "clientId": "280vipunap1fn5lod03esd5e39",
    "region": "us-east-1",
    "api": "https://production-ispeak-us-east-1.auth.us-east-1.amazoncognito.com/oauth2"
  }
};
<header class="header modal-blur">
  <h1 class="h1" [innerHtml]="content.title"></h1>
</header>
<page-viewer class="content-panel modal-blur" [ngClass]="currentPageClass" [history]="false">
  <page-template [id]="Page.Home" title="Home">
  </page-template>
  <page-template [id]="Page.Home" title="Swagger">
    <ng-template><swagger></swagger></ng-template>
  </page-template>
</page-viewer>

<messages [z-index]="12000"></messages>
<cognito [pool-id]="environment.cognito.poolId" [client-id]="environment.cognito.clientId" [cognito-signin-url]="environment.cognito.endpoint" (authenticated)="onAuthenticated($event)" (response)="onResponse($event)" (connecting)="onConnecting($event)"></cognito>
<modal class="modal" [transition-speed]="250" [z-index]="10001" background="rgba(255,255,255,0.25)"></modal>
<loader [transition-speed]="250" [animation-duration]="loaderDuration" [z-index]="10000" [size]="loaderSize" color="#0099ff" background="rgba(255,255,255,.25)" ></loader>
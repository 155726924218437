import { Component, OnInit, AfterViewInit, ViewChild, TemplateRef } from '@angular/core';
import { LoaderService, LoaderType } from "ems-web-app-loader";
import { PageViewerService } from "ems-web-app-page-viewer";
import { ModalService, ModalData } from "ems-web-app-modal";
import { trace, delay, kebab, tick } from "ems-web-app-utils";
import { MessagesService, MessageType, MessagePosition } from "ems-web-app-messages";
import { Page } from "../../classes";
import { AppService, HttpService, ContentService } from "../../services";
import { CognitoService, CognitoFormType, CognitoStrings, ICognitoUserData } from "ems-web-app-cognito";
import { ViewContainerService } from "ems-web-app-view-container";
import { environment } from "../../environments/environment";

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.less']
})
export class AppComponent implements OnInit, AfterViewInit {

  @ViewChild("exampleModalTemplate") template!: TemplateRef<any>;

  public initialized: boolean = false;
  public transitioning: boolean = false;
  public loaderDuration: number = 1000;
  public loaderSize: number = 200;
  public loading: boolean = false;
  public Page = Page;
  public currentPageClass: string = "";
  public authenticated: boolean = false;
  public environment = environment;
  public paginating: boolean = false;

  public userInfo: ICognitoUserData | null = null;

  constructor(public content: ContentService, public loader: LoaderService, public viewer: PageViewerService, 
                private app: AppService, private http: HttpService, private modal: ModalService,
                private messages: MessagesService, 
                private cognito: CognitoService, private viewContainer: ViewContainerService) {

  }

  ngOnInit() {
    trace(this.environment);
    this.viewer.page.subscribe(page => {
      this.currentPageClass = kebab(page ?? "");
    });

    this.cognito.session$.subscribe(session => {
      delay(() => this.authenticated = session ? true : false );
    });
  }

  ngAfterViewInit() {
    this.initialize();
  }

  onConnecting(connecting: any) {
    this.loader.load(connecting);
  }

  onAuthenticated(info: ICognitoUserData | null) {
    trace(info);
    this.userInfo = info;
    this.viewer.setCurrentPage(Page.Home);
  }

  onResponse($event: any) {
    trace($event);
  }

  login() {
    this.cognito.showForm(CognitoFormType.Login);
  }

  logout() {
    this.loader.show();
    this.cognito.logout();
    window.location.reload();
  }

  public onCancelModal = () => {
    this.modal.setCurrentModal(null);
  }

  private async initialize() {
    await tick();
    this.initialized = true;

    if(this.userInfo) this.viewer.setCurrentPage(Page.Home);
    else this.login();
  }
}
